import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  posts: [],
  postid: "",
};

const getters = {
  allPosts: (state) => state.posts,
  getpostid: (state) => state.postid,
};

const mutations = {
  setPosts(state, postdata) {
    state.posts = postdata;
  },

  PostAddition(state, post) {
    state.posts.push(post);
  },

  delPost(state, postid) {
    state.posts = state.posts.filter((post) => postid !== post.postid);
  },
  setPostid(state, payload) {
    state.postid = payload;
  },

  updatePost(state, { index, updatedPost }) {
    // Replace the post at the specified index with the updated post
    state.posts.splice(index, 1, updatedPost);
  },
};

const actions = {
  fetchPosts({ commit }) {
    const apiUrl = "post";
    return private_url.get(apiUrl).then((response) => {
      commit("setPosts", response.data);
      return response;
    });
  },

  addPost({ commit }, payload) {
    const apiUrl = "post";
    return private_url.post(apiUrl, payload).then((response) => {
      console.log("add data", response.data);
      commit("PostAddition", response.data.data);
      return response;
    });
  },

  deletePost({ commit }, postid) {
    const apiUrl = `post/${postid}`;
    return private_url.delete(apiUrl).then((response) => {
      commit("delPost", postid);
      return response;
    });
  },
  showPost({ commit }, postid) {
    const apiUrl = `post/${postid}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  editPost({ state, commit }, payload) {
    console.log("paylod", payload);
    const apiUrl = "post/edit";
    return private_url.post(apiUrl, payload).then((response) => {
      // Update the post in the existing position
      const updatedPost = {
        postid: payload.postid,
        designation: payload.postDesignation,
        nominee: payload.postNominee,
        description: payload.postDescription,
      };
      const index = state.posts.findIndex(
        (post) => post.postid === payload.postid
      );
      if (index !== -1) {
        commit("updatePost", { index, updatedPost });
      }
      return response;
    });
  },

  PostNameID() {
    const apiUrl = "postName";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
