import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  report_id: "",
};

const getters = {
  getReportid: (state) => state.report_id,
};

const mutations = {
  setReportid(state, payload) {
    state.report_id = payload;
  },
};

const actions = {
  fetchReports({ commit }, payload) {
    console.log("payloda", payload);
    const apiUrl = "report";
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },

  excelDownload({ commit }, payload) {
    console.log("payloda", payload, { responseType: "blob" });
    const apiUrl = "exportexcel";
    return private_url
      .post(apiUrl, payload, { responseType: "blob" })
      .then((response) => {
        return response;
      });
  },

  pdfDownload({ commit }, payload) {
    const apiUrl = "exportpdf";
    return private_url
      .post(apiUrl, payload, { responseType: "arraybuffer" }) // Use responseType: "arraybuffer" to receive binary data
      .then((response) => {
        return response.data; // Return the PDF data from the response
      });
  },

  fetchRegions() {
    const apiUrl = "category/getregions";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  fetchDistricts() {
    const apiUrl = "category/getdistricts";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  fetchTehsil() {
    const apiUrl = "category/gettehsils";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  fetchRegionData(context, payload) {
    console.log("payload", payload);
    const apiUrl = `category/getregiondata/${payload}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  fetchReportData(context, payload) {
    console.log("payload", payload);
    const apiUrl = "category/getreport";
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },

  fetchRegionsforCatDetail() {
    const apiUrl = "reportRegionsforcatDetail";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  fetchDistrictsforCatDetail(context, categoryid) {
    console.log("store Function", categoryid);
    const apiUrl = `reportDistrictsforcatDetail/${categoryid}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  // fetchTehsilsforCatDetail(context, categoryid) {
  //   console.log("store Function", categoryid);
  //   const apiUrl = `reportTehsilsforcatDetail/${categoryid}`;
  //   return private_url.get(apiUrl).then((response) => {
  //     return response;
  //   });
  // },
  fetchCompleteVC(context, categoryid) {
    console.log("store Function", categoryid);
    const apiUrl = `reportCompleteVc/${categoryid}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  fetchInCompleteVC(context, categoryid) {
    console.log("store Function", categoryid);
    const apiUrl = `reportInCompleteVc/${categoryid}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
