<template>
  <div>
    <v-fade-transition mode="out-in">
    <component :is="layout">
      <router-view />
    </component>
    </v-fade-transition>
    
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
