import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  wings: [],
  wingid: "",
};

const getters = {
  allWings: (state) => state.wings,
  getwingid: (state) => state.wingid,
};

const mutations = {
  setWings(state, payload) {
    state.wings = payload;
  },
  setWingid(state, payload) {
    state.wingid = payload;
  },
  WingAddition(state, wing) {
    state.wings.push(wing);
  },
  delWing(state, wingid) {
    state.wings = state.wings.filter((wing) => wingid !== wing.wingid);
  },
  updateWing(state, { index, updatedWing }) {
    // Replace the post at the specified index with the updated post
    state.wings.splice(index, 1, updatedWing);
  },
};

const actions = {
  fetchWings({ commit }) {
    const apiUrl = "wing";
    return private_url.get(apiUrl).then((response) => {
      commit("setWings", response.data);
      return response;
    });
  },

  addWing({ commit }, payload) {
    const apiUrl = "wing";
    return private_url.post(apiUrl, payload).then((response) => {
      console.log("add wing", response.data);
      commit("WingAddition", response.data.data);
      return response;
    });
  },

  deleteWing({ commit }, wingid) {
    const apiUrl = `wing/${wingid}`;
    return private_url.delete(apiUrl).then((response) => {
      console.log("response1", response);
      commit("delWing", wingid);
      console.log("response2", response);
      return response;
    });
  },

  showWing({ commit }, wingid) {
    const apiUrl = `wing/${wingid}`;
    return private_url
      .get(apiUrl)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error while fetching wing data:", error);
        throw error; // Rethrow the error to be handled in the calling code.
      });
  },

  editWing({ state, commit }, payload) {
    console.log("paylod", payload);
    const apiUrl = "wing/edit";
    return private_url.post(apiUrl, payload).then((response) => {
      // Update the post in the existing position
      const updatedWing = {
        wingid: payload.wingid,
        wingname: payload.wingName,
        wingdescription: payload.wingDescription,
      };
      const index = state.wings.findIndex(
        (wing) => wing.wingid === payload.wingid
      );
      if (index !== -1) {
        commit("updateWing", { index, updatedWing });
      }
      return response;
    });
  },

  WingNameID() {
    const apiUrl = "wingName";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
