<template>
  <div>
    <v-app>
      <v-main class="main-background">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-background {
  background: #CB0A0B;
  background: linear-gradient(90deg, #219240 0%, #CB0A0B 100%);
}
</style>
