import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "../store/modules/auth";
import category from "../store/modules/category";
import post from "../store/modules/post";
import wing from "../store/modules/wing";
import role from "../store/modules/role";
import organization from "../store/modules/organization";
import report from "../store/modules/report";
import dashboard from "../store/modules/dashboard";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    category,
    post,
    wing,
    role,
    organization,
    report,
    dashboard,
  },
});
