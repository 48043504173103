import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  roles: [],
  role_id: "",
  role_name: "",
  role_description: "",
};

const getters = {
  allRoles: (state) => state.roles,
  getroleid: (state) => state.role_id,
  getrolename: (state) => state.role_name,
  getroledescription: (state) => state.role_description,
};

const mutations = {
  RoleAddition(state, role) {
    state.roles.push(role);
  },

  setRoles(state, roledata) {
    state.roles = roledata;
  },
  setRoleid(state, payload) {
    state.role_id = payload;
  },
  setRoleName(state, payload) {
    state.role_name = payload;
  },
  setRoleDescription(state, payload) {
    state.role_description = payload;
  },
  updateRole(state, { index, updatedRole }) {
    console.log("updated", updatedRole);
    console.log("before ", state.roles);
    // Replace the post at the specified index with the updated post
    Object.assign(state.roles[index], updatedRole);
    console.log("after ", state.roles);
  },

  delRole(state, role_id) {
    state.roles = state.roles.filter((role) => role_id !== role.role_id);
  },
};

const actions = {
  fetchRoles({ commit }) {
    const apiUrl = "role";
    return private_url.get(apiUrl).then((response) => {
      commit("setRoles", response.data);
      return response;
    });
  },

  addRole({ commit }, payload) {
    const apiUrl = "role";
    return private_url.post(apiUrl, payload).then((response) => {
      console.log("add data", response.data);
      commit("RoleAddition", response.data.data);
      return response;
    });
  },

  showRole({ commit }, role_id) {
    const apiUrl = `role/${role_id}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  deleteRole({ commit }, role_id) {
    const apiUrl = `role/${role_id}`;
    return private_url.delete(apiUrl).then((response) => {
      commit("delRole", role_id);
      return response;
    });
  },

  editRole({ state, commit }, payload) {
    console.log("payload", payload);
    const apiUrl = "role/edit";
    return private_url.post(apiUrl, payload).then((response) => {
      const updatedRole = {
        role_name: payload.roleName,
        description: payload.roleDescription,
      };

      const index = state.roles.findIndex(
        (role) => role.role_id === payload.role_id
      );
      if (index !== -1) {
        commit("updateRole", { index, updatedRole });
      }

      return response;
    });
  },
  RoleNameID() {
    const apiUrl = "roleNames";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
