<template>
  <v-app>
    <!-- <v-app-bar app color="white" dark class="elevation-3 app-bar-bg">
      <img src="../assets/logo.png" width="240px" height="100px" class="mt-1" />
      <div class="d-flex align-center font-weight-bold"></div>
      <v-divider vertical class="ml-6"></v-divider>

      <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main class="main-background">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <v-footer color="white subtitle-2 font-weight-medium" padless>
      <v-row justify="center" no-gutters>
        <v-col class="footer-font-color py-2 text-center" cols="12">
          © {{ new Date().getFullYear() }} — <strong>PTI-Shajar</strong> - All
          Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "RegisterOrganizationLayout",
};
</script>

<style lang="scss" scoped>
.main-background {
  background-color: #f6f7fc !important;
}
.footer-font-color {
  color: #5f6368 !important;
}
.app-bar-bg {
  background: white;
  background: white;
}
</style>
