<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="d-none d-sm-flex">
      <template v-slot:prepend>
        <div class="text-center">
          <!-- <v-list-item>
<v-list-item-content>
  <v-list-item-title class="text-h6"> PTI </v-list-item-title>
  <v-list-item-subtitle> Shajar - Logo </v-list-item-subtitle>
</v-list-item-content>
</v-list-item> -->
          <img src="../assets/logo.svg" width="70%" class="mt-1" />
        </div>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-avatar rounded>
            <v-avatar size="40" color="SecondaryLight secondary--text" rounded>
              {{
                mainUser.username ? mainUser.username[0].toUpperCase() : "N/A"
              }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-1 font-weight-medium">{{
              mainUser.username
            }}</v-list-item-title>
            <v-list-item-subtitle class="caption font-weight-medium">{{
              mainUser.cnic
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list class="pb-0">
          <div class="text-center">
            <div class="mb-0 mt-1">
              <v-avatar color="primaryLight" size="55" rounded>
                <span  class="primary--text headline font-weight-medium">
                  {{
                mainUser.username
                  ? mainUser.username[0].toUpperCase()
                  : "N/A"
              }}</span
                >
               
              </v-avatar>
            </div>
            <v-list class="pa-0 ma-0">
              <v-list-item class="mb-0 pb-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium">
                    {{ mainUser.username }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ mainUser.cnic }}</v-list-item-subtitle>
                  <v-list-item-subtitle><v-chip color="SecondaryLight" x-small class="secondary--text">{{ mainUser.role_name }}</v-chip></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          </v-list> -->
        <v-divider></v-divider>
      </template>
      <v-list nav>
        <v-list-item to="/dashboard" link active-class="primary--text">
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20"> mdi-monitor-dashboard</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Dashboard</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/category"
          link
          active-class="primary--text"
          class="d-none d-sm-flex"
        >
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20"> mdi-weather-tornado</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Category</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/post"
          link
          active-class="primary--text"
          class="d-none d-sm-flex"
        >
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20"> mdi-post-outline</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Post</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/wings"
          link
          active-class="primary--text"
          class="d-none d-sm-flex"
        >
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20">mdi-wind-power-outline</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Wings</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/organization" link active-class="primary--text">
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20">
                mdi-chart-scatter-plot-hexbin</v-icon
              >
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Organization</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/roles"
          link
          active-class="primary--text"
          class="d-none d-sm-flex"
        >
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20">
                mdi-order-bool-descending-variant</v-icon
              >
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Role Manager</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/reports" link active-class="primary--text">
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <v-icon color="primary" size="20">mdi-chart-timeline</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subtitle-2 font-weight-medium"
              >Reports</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-avatar>
              <v-avatar color="primaryLight" size="40">
                <v-icon color="primary" size="20">mdi-chart-timeline</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 font-weight-medium"
                >All Reports</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!-- vendor -->
          <v-list-item to="/reports" link>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>OB Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/categoryreport" link class="mt-n1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Category Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/categorydetailreport" link class="mt-n1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Category Detail Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-0 SecondaryLight">
          <v-list-item class="mb-0 pb-0">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 font-weight-medium">
                <v-icon color="secondary" left size="18" class="mt-n1">
                  mdi-order-bool-descending-variant</v-icon
                >
                Role
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip
                color="white"
                small
                label
                class="font-weight-medium"
                text-color="secondary"
                >{{ mainUser.role_name }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark class="elevation-3 app-bar-bg">
      <v-toolbar-title class="d-flex d-sm-none">
        <img
          src="../assets/logo.svg"
          width="120px"
          height="56px"
          class="ml-n7"
        />
      </v-toolbar-title>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="d-none d-sm-flex"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <v-menu offset-y transition="scale-transition" origin="center center">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            size="40"
            color="white primary--text"
            v-bind="attrs"
            v-on="on"
          >
            P
          </v-avatar>
        </template>
        <v-list dense>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-avatar
                size="40"
                color="primary white--text"
                v-bind="attrs"
                v-on="on"
              >
                P
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ mainUser.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ mainUser.cnic }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip color="secondary" small>{{ mainUser.role_name }}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-face-man-profile</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile Settings</v-list-item-title>
          </v-list-item>
          <v-list-item >
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <img
        src="../assets/ayat.png"
        width="152px"
        height="48px"
        class="d-flex d-sm-none"
      />
      <img
        src="../assets/ayat.png"
        width="350px"
        height="63px"
        class="d-none d-sm-flex"
      />
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="logout" class="mr-0" v-bind="attrs" v-on="on">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>
    <v-bottom-navigation app class="d-flex d-sm-none" color="primary" grow>
      <v-btn to="/dashboard">
        <span>Dashboard</span>

        <v-icon> mdi-monitor-dashboard</v-icon>
      </v-btn>

      <v-btn to="/organization">
        <span>Organization</span>

        <v-icon> mdi-chart-scatter-plot-hexbin</v-icon>
      </v-btn>
      <v-menu offset-y min-width="120" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <span>All Reports</span>
            <v-icon>mdi-chart-timeline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-subheader>All Reports</v-subheader>
          <v-list-item to="/reports" link>
            <v-list-item-title>OB Report</v-list-item-title>
          </v-list-item>
          <v-list-item to="/categoryreport" link>
            <v-list-item-title>Category Report</v-list-item-title>
          </v-list-item>
          <v-list-item to="/categorydetailreport" link>
            <v-list-item-title>Category Detail Report</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-bottom-navigation>
    <v-main class="main-background pb-15">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <v-footer color="white subtitle-2 font-weight-medium" padless>
      <v-row justify="center" no-gutters>
        <v-col
          class="footer-font-color py-2 text-center footer-bar-bg"
          cols="12"
        >
          © {{ new Date().getFullYear() }} —
          <strong class="footer-bar-bg">PTI-Shajar</strong> - All Rights
          Reserved
        </v-col>
      </v-row>
    </v-footer>

    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout" color="">
        {{ text }}
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { private_url } from "@/store/axios";
import { mapGetters } from "vuex";
export default {
  name: "InnerSystemLayout",
  data() {
    return {
      mainUser: null,
      snackbar: false,
      text: "",
      timeout: 2000,
      color: "",
      drawer: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["getdashboardPermission", "getdataUser"]),
  },
  methods: {
    userProfile() {
      this.mainUser = this.getdataUser;
    },
    logout() {
      const apiUrl = "logout";
      return private_url
        .get(apiUrl)
        .then((response) => {
          localStorage.clear();
          this.snackbar = true;
          this.$router.go("login");
          this.color = "primary";
          this.text = response.data.message;
        })
        .catch(() => {
          localStorage.clear();
          this.$router.go("login");
          console.log("error- logout", event);
        });
    },
  },
  created() {
    this.userProfile();
  },
};
</script>

<style lang="scss" scoped>
.main-background {
  background-color: #f6f7fc !important;
}
.footer-font-color {
  color: #5f6368 !important;
}
.app-bar-bg {
  background: #219240;
  background: linear-gradient(90deg, #219240 0%, #cb0a0b 100%);
}
</style>
