import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import InnerSystemLayout from "../layout/InnerSystemLayout";
import RegisterOrganizationLayout from "../layout/RegisterOrganizationLayout";
import AboutView from "../views/Register";

Vue.use(VueRouter);

const routes = [
  {
    path: "/register/:param1",
    name: "Register",
    meta: {
      layout: "RegisterOrganizationLayout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "login-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },

  {
    path: "/",
    name: "app",
    meta: {
      layout: InnerSystemLayout,
      requiresAuth: true,
    },
    redirect: { name: "dashboard" },
    component: InnerSystemLayout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Dashboard.vue"),
        meta: {
          name: "dashboard",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },

      {
        path: "category",
        name: "Category",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/Category.vue"),
        meta: {
          name: "category",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },

      {
        path: "organization",
        name: "Organization",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/Organization.vue"),
        meta: {
          name: "organization",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },

      {
        path: "post",
        name: "Post",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/Post.vue"),
        meta: {
          name: "post",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },
      {
        path: "wings",
        name: "Wings",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/Wings.vue"),
        meta: {
          name: "wings",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },
      {
        path: "roles",
        name: "Roles Manager",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/RolesManager.vue"),
        meta: {
          name: "roles",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },

      {
        path: "reports",
        name: "Reports",
        // props: true,

        component: () =>
          import(/* webpackChunkName: "pricing" */ "../views/Reports.vue"),
        meta: {
          name: "reports",
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
      },
      {
        path: "categoryreport",
        name: "categoryreport",
        meta: {
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pricing" */ "../components/Reports/CategoryReports.vue"
          ),
      },
      {
        path: "categorydetailreport",
        name: "categorydetailreport",
        meta: {
          layout: "InnerSystemLayout",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pricing" */ "../components/Reports/CategoryDetailReports.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/getToken"]) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    if (store.getters["auth/getToken"]) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }

  document.title = `${to.name.charAt(0).toUpperCase() + to.name.slice(1)} - ${
    process.env.VUE_APP_TITLE
  }`;

  next();
});

// router.onError((error) => {
//   if (error.code === "ERR_NETWORK") {
//     // Backend is disconnected or network error occurred
//     // Redirect to the logout route or perform any other desired action
//     router.replace({ name: "logout" });
//   }
// });

export default router;
