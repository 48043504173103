import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Vuelidate from "vuelidate";
import vuetify from "./plugins/vuetify";
import loginLayout from "./layout/login-layout.vue";
import RegisterOrganizationLayout from "./layout/RegisterOrganizationLayout.vue";
import ElementUI from "element-ui";
import { Table, TableColumn } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import VueSocialSharing from "vue-social-sharing";

Vue.component("login-layout", loginLayout);
Vue.component("RegisterOrganizationLayout", RegisterOrganizationLayout);
Vue.use(ElementUI, { locale });
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
