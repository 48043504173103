import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
const theme = {
    // primary: '#2c8344',
    // secondary: '#606060',
    // success: '#00C853',
    // error: '#9f1e1f',
    // Warning:'#FFC260',
    // accent:"#030303",
    // info: '#2196F3',


    primary: '#2eaf52',
    secondary: '#e71314',
    success: '#00C853',
    error: '#e71314',
    Warning:'#FFC260',
    accent:"#030303",
    info: '#2196F3',
    lightcolor:'#f6f7fc',



    // light Colors
    primaryLight:"#D9F7E5",
    SecondaryLight: '#FFE5E5',
    SuccessLight:'#D9F7E5',
    ErrorLight:'#FFE5E5',
    WarrningLight:'#FFF0D9',
    InfoLight:'#D2EAFD',
    AccentLight:'#ECEFF1',
    topBarBtnColor:"#030303"
}
export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    }
});
